.top_bar {
    width: fit-content;
    padding-top: 5px;
    margin: auto;
}
.top_bar > img {
    display: block;
}

.content {
    margin-top: 0px;
}


@media only screen and (min-width: 1250px) {
    .top_bar {
        margin-left: 30px;
        margin-bottom: 5px;
    }
}