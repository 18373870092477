.main {
    padding: 1em;
    background-color: white;
    color: black;
    max-width: 600px;
    min-width: 370px;
    padding: 20px;
}

.main > span {
    text-align: right;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    right: 20px;
}

.main > span:hover {
    cursor: pointer;
}