@font-face {
  font-family: Ubuntu;
  src: url('./fonts/Ubuntu/Ubuntu-Regular.ttf'), url('./fonts/Ubuntu/Ubuntu-Bold.ttf'), url('./fonts/Ubuntu/Ubuntu-BoldItalic.ttf'), url('./fonts/Ubuntu/Ubuntu-Italic.ttf'),
        url('./fonts/Ubuntu/Ubuntu-Light.ttf'), url('./fonts/Ubuntu/Ubuntu-LightItalic.ttf'), url('./fonts/Ubuntu/Ubuntu-Medium.ttf'),
        url('./fonts/Ubuntu/Ubuntu-MediumItalic.ttf');
}

@font-face {
  font-family: 'Work Sans';
  src: url('./fonts/WorkSans-Regular.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', 'Ubuntu', "Share Tech", "Share Tech Mono", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: rgb(128,254,254);
  background: -moz-linear-gradient(90deg, rgba(128,254,254,1) 0%, rgba(164,253,90,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(128,254,254,1) 0%, rgba(164,253,90,1) 100%);
  background: linear-gradient(90deg, rgba(128,254,254,1) 0%, rgba(164,253,90,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#80fefe",endColorstr="#a4fd5a",GradientType=1);
}

code {
  font-family: "Ubuntu", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 1200px) {
  body {
    background: rgb(128,254,254);
    background: -moz-linear-gradient(135deg, rgba(128,254,254,1) 0%, rgba(164,253,90,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(128,254,254,1) 0%, rgba(164,253,90,1) 100%);
    background: linear-gradient(135deg, rgba(128,254,254,1) 0%, rgba(164,253,90,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#80fefe",endColorstr="#a4fd5a",GradientType=1);
  }
}