.media {
    min-width: 00px;
    max-height: 359px;
    height: 100%;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3),
                -3px -3px 5px rgba(255, 255, 255, 0.05);
    border-radius: 20px;
}

.loader {
    margin: auto;
}

.hidden {
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
}

.media_container {
    text-align: center;
}